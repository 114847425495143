.ant-spin-nested-loading .ant-spin-blur::after {
	opacity: 0;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error,
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item-error
	.ant-upload-icon
	.anticon,
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item-error
	.ant-upload-list-item-name,
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item-error
	.ant-upload-list-item-actions
	.anticon {
	color: gray !important;
}

.ant-select-item.ant-select-item-option {
	padding: 10px 12px;
}

.ant-tabs .ant-tabs-tab-remove {
	margin-left: 0;
}

.ant-radio-button-wrapper {
	height: unset;
}

.ant-radio-button-wrapper-checked:not(
		:where(
				.css-dev-only-do-not-override-1mx2m0
			).ant-radio-button-wrapper-disabled
	)::before {
	display: none;
}

.ant-radio-group-solid
	:where(
		.css-dev-only-do-not-override-1w0m64l
	).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: black;
	color: #fcb617;
}

.ant-radio-button-wrapper {
	background: transparent;
	color: white;
}

.ant-radio-group-solid
	:where(
		.css-dev-only-do-not-override-1w0m64l
	).ant-radio-button-wrapper-checked:not(
		.ant-radio-button-wrapper-disabled
	):hover {
	background: black;
}

.ql-editor,
.ant-table-cell {
	h1,
	h2,
	h3,
	h4,
	h5 {
		color: black;
	}
}

.content-blog {
	* {
		color: white !important;
	}
}
