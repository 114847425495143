.login,
.register {
	background-image: url("../../../public/images/img/rectangle.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100%;
	max-width: 100vw;
	min-height: 100vh;
	width: 100vw;
	text-align: center;
}

.login img,
.register img {
	width: 200px;
}

.login h1,
.register h1 {
	font-size: 35px;
	line-height: 60px;
	font-family: "IM FELL Double Pica SC";
	color: #efcd7f;
	margin: -10px auto 0;
}

.login form {
	background-color: #d9d9d9;
	width: 40%;
	margin: 50px auto 0;
	position: relative;
	padding-bottom: 50px;
}

.login form p {
	background: linear-gradient(
		102.66deg,
		#ffdb63 31.25%,
		#f4df9b 66.82%,
		#fec450 96.93%
	);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-size: 22px;
	line-height: 48px;
	color: #000;
	padding: 20px 0;
	font-weight: 600;
}

.login form .inner {
	padding: 0 40px;
}

.login form .form-item {
	display: flex;
	align-items: center;
	gap: 30px;
	padding: 0 20px;
	font-size: 18px;
}

.login form .form-item:first-child {
	margin-top: 60px;
}

.login form .form-item img {
	width: 50px;
	height: 50px;
	font-size: 18px;
}

.login form .inner input {
	border: none;
	outline: none;
	padding: 10px;
	background-color: transparent;
	font-size: 32px;
	line-height: 48px;
	font-size: 22px;
}

.login form .divider {
	width: 100%;
	margin: 20px 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.61);
}

.login form .submit-btn {
	position: relative;
	bottom: 10%;
	left: -2.5%;
	border: none;
	font-size: 24px;
	margin-top: 50px;
	line-height: 54px;
	font-family: "Inika";
	padding: 10px;
	width: 105%;
	background: linear-gradient(
		102.66deg,
		#ffdf75 31.25%,
		#ffeaa6 66.82%,
		#ffc249 96.93%
	);
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
	font-weight: 600;
}

.login form .submit-btn::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: -6px;
	border-width: 0 20px 6px;
	border-style: solid;
	width: 100px;
	border-color: transparent currentColor transparent currentColor;
}

.login .navigate,
.register .navigate {
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
	width: 40%;
	margin: auto;
	margin-top: 10px;
	margin-left: 40%;
}

.login .navigate p,
.register .navigate p {
	color: #fff;
	font-size: 30px;
	line-height: 60px;
	font-family: "Inika";
	margin: 0;
	font-size: 20px;
}

.login .navigate a,
.register .navigate a {
	color: #efcd7f;
	font-size: 20px;
	line-height: 60px;
	font-family: "Inika";
	margin-left: 2%;
}

.register form {
	border: 2px solid #f6b531;
	padding: 80px 70px 15px;
	text-align: center;
	position: relative;
	width: 50%;
	margin: auto;
	margin-top: 80px;
}

.register form p {
	position: absolute;
	margin: 0;
	font-size: 20px;
	line-height: 39px;
	padding: 9px 11px;
	top: -7%;
	color: #000;
	left: 50%;
	transform: translate(-50%);
	width: 30%;
	background: linear-gradient(
		54deg,
		#e0a23b 0%,
		#f4ed93 18.23%,
		#fcb045 51.16%,
		#f4ed93 82.29%,
		#e0a23b 99.99%,
		#f4ed93 100%
	);
	border: 1px solid #f4df9b;
	border-radius: 5px;
	font-weight: 500;
}

.register input,
.register label {
	padding: 11px 38px;
	background: linear-gradient(
		102.66deg,
		#ffdb63 31.25%,
		#f4df9b 66.82%,
		#fec450 96.93%
	);
	border: none;
	outline: none;
	font-size: 18px;
	line-height: 28px;
	font-family: "Inika";
	color: #00000070;
	margin: auto;
	margin-bottom: 15px;
	display: block;
	border-radius: 5px;
	border: 1px solid #f4df9b;
}

.register .input-field {
	width: 100%;
}

.register label {
	cursor: pointer;
	text-align: left;
}

.register input::placeholder {
	color: #00000070;
}

.register .dropdown {
	position: relative;
}

.register .dropdown-list {
	position: absolute;
	border-radius: 5px;
	color: #00000070;
	left: 0;
	right: 0;
	background: linear-gradient(
		102.66deg,
		#ffdb63 31.25%,
		#f4df9b 66.82%,
		#fec450 96.93%
	);
	text-align: left;
	padding: 10px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s linear, visibility 0.2s linear;
}

.register input[type="number"]::-webkit-inner-spin-button,
.register input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.register .dropdown-list__item {
	padding: 10px 28px;
	font-size: 18px;
	line-height: 28px;
	font-family: "Inika";
	cursor: pointer;
	border-radius: 5px;
	transition: all 0.1s ease-in;
}

.register .dropdown-list__item:hover {
	background: #fff;
}

.register .dropdown-list.active {
	opacity: 1;
	visibility: visible;
}

.register form select {
	font-family: "IM FELL Double Pica";
	outline: none;
	border-radius: 5px;
	padding: 11px 34px;
	width: 100%;
	margin-bottom: 15px;
	background: linear-gradient(
		102.66deg,
		#ffdb63 31.25%,
		#f4df9b 66.82%,
		#fec450 96.93%
	);
	color: #6c757d;
	font-size: 18px;
	line-height: 28px;
	color: #00000070;
}

.register select option {
	font-family: "IM FELL Double Pica";
	font-size: 16px;
	color: #6c757d;
}
