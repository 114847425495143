.login {
	&__container {
		background: rgb(39, 47, 40);
		background: linear-gradient(
			22deg,
			rgba(39, 47, 40, 1) 24%,
			rgba(223, 214, 154, 1) 53%,
			rgba(135, 127, 83, 1) 88%
		);
		max-height: 100vh;
		max-width: 100vw;
		height: 100vh;
		width: 100vw;
		padding: 3rem 0;
	}

	&__inner {
		max-width: 1200px;
		width: 1200px;
		margin: auto;
		height: 100%;
	}

	&__logo {
		width: 10rem;
	}

	&__form {
		h3 {
			color: black;
			text-align: center;
			margin-bottom: 40px;
		}

		input {
			width: 100%;
			border: 1px solid lightgray;
			padding: 10px 15px;
			color: gray;
			font-size: 1rem;
			border-radius: 8px;
			outline: none;
			margin-bottom: 20px;
			background-color: white;

			&::placeholder {
				color: gray;
				font-size: 1rem;
			}

			&[type="submit"] {
				background: rgb(39, 47, 40);
				background: linear-gradient(
					22deg,
					rgba(39, 47, 40, 1) 0%,
					rgba(223, 214, 154, 1) 53%,
					rgba(135, 127, 83, 1) 100%
				);
				color: black;
				font-weight: 600;
				padding: 15px;
			}
		}
	}
}
