@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-quill/dist/quill.snow.css";

/* width */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(160, 159, 159);
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/video-modal";
@import "common/sidebar";
@import "pages/login";
@import "pages/news";
@import "pages/register";
@import "overrides/overrides";
