@import "../../scss/default/color_variable";
.cs-testimonial.cs-style1 {
	position: relative;
	padding-left: 70px;
	padding-top: 70px;
	.cs-testimonial_quote {
		position: absolute;
		top: 0;
		left: 1px;
		font-size: 42px;
		color: $primary;
	}
	.cs-testimonial_text {
		font-size: 16px;
		line-height: 1.5em;
		font-weight: 300;
		color: $primary;
	}
	.cs-rating {
		margin-top: 24px;
	}
	.cs-testimonial_avatar_name {
		font-size: 22px;
		margin-bottom: 0;
		margin-top: 70px;
	}
	.cs-testimonial_avatar_designation {
		margin-top: 10px;
		&::before {
			content: "";
			height: 2px;
			width: 20px;
			background-color: #d9d9d9;
			display: inline-block;
			position: relative;
			top: -4px;
			border-radius: 3px;
			margin-right: 10px;
		}
	}
}
@media screen and (max-width: 1380px) {
	.cs-testimonial.cs-style1 .cs-testimonial_text,
	.cs-testimonial.cs-style1 .cs-testimonial_avatar_name {
		font-size: 20px;
	}
}
@media screen and (max-width: 991px) {
	.cs-testimonial.cs-style1 {
		padding-left: 20px;
		padding-top: 60px;
	}
	.cs-testimonial.cs-style1 .cs-testimonial_avatar_name {
		margin-top: 30px;
	}
}
